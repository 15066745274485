<template>
    <div>

    </div>
</template>

<script>
import gql from "graphql-tag";

export default {
    name: "ConfirmEmailPage",
    mounted() {
        if (this.$route.params.token) {
            this.$apollo.mutate({
                mutation: gql`mutation ($token:String!){confirmEmail(token:$token)}`,
                variables: {
                    token: this.$route.params.token
                }
            }).then(() => {
                this.$router.push('/login')

            })
        }
    },
}
</script>

<style scoped>

</style>